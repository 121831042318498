<template>
  <div class="page-statlogist">
    <el-row type="flex" justify="space-between" align="center">
      <el-col>
        <el-page-header content="Статистика по логистам" title="на главную" @back="$router.push('/')"></el-page-header>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-form>
      <el-row>
        <el-col :span="7">
          <el-form-item label="Период">
            <el-date-picker v-model="period" type="daterange" unlink-panels @change="statLogists(period)">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="changeDate('month')">Месяц</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="changeDate('week')">Неделя</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="changeDate('day')">День</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-divider></el-divider>
    <el-table :summary-method="getSummaries"  show-summary max-height="700" stripe style="width: 100%; margin-bottom: 40px;" :data="logists">
      <el-table-column  width="180">
        <template slot-scope="scope">
          {{ user(scope.row.id) }}
        </template>
      </el-table-column>
      <el-table-column label="Лидов вошло">
        <el-table-column label="Всего" prop="total"></el-table-column>
        <el-table-column label="Новые" prop="chat"></el-table-column>
        <el-table-column label="Постоянные" width="120" prop="regular"></el-table-column>
      </el-table-column>
      <el-table-column label="Сделок закрыто">
        <el-table-column label="Всего" prop="total_closed"></el-table-column>
        <el-table-column label="Новые" prop="chat_closed"></el-table-column>
        <el-table-column label="Постоянные" width="120" prop="regular_closed"></el-table-column>
        <el-table-column label="Доля постоянных" width="120" prop="regular_percent"></el-table-column>
        <el-table-column label="Конверсия всего" width="150" prop="conversion_total"></el-table-column>
        <!--<el-table-column label="Конверсия постоянная" width="150" prop="conversion_chat"></el-table-column>-->
        <el-table-column label="Конверсия новых" width="150" prop="conversion_new"></el-table-column>
      </el-table-column>
      <el-table-column label="Денег получено">
        <el-table-column label="Всего" prop="money_total"></el-table-column>
        <el-table-column label="Новые" prop="money_chat"></el-table-column>
        <el-table-column label="Постоянные" width="120" prop="money_regular"></el-table-column>
        <el-table-column label="Доля постоянных" width="120" prop="money_percent"></el-table-column> 
      </el-table-column>
      <el-table-column label="Денег ожидаем">
        <el-table-column label="Всего" prop="moneywait_total"></el-table-column>
        <el-table-column label="Новые" prop="moneywait_chat"></el-table-column>
        <el-table-column label="Постоянные" width="120" prop="moneywait_regular"></el-table-column>
      </el-table-column>
       <el-table-column label="Средний чек">
        <el-table-column label="Всего" prop="average_total"></el-table-column>
        <el-table-column label="Новые" prop="average_chat"></el-table-column>
        <el-table-column label="Постоянные" width="120" prop="average_regular"></el-table-column>
        <el-table-column label="Выручка на заявку" width="120" prop="average_chat"></el-table-column>
      </el-table-column>
      <el-table-column label="Кол-во клиентов" prop="contacts"></el-table-column>
   
    </el-table>
  </div>
</template>
    

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      period: [moment("2022-06-01"), moment("2022-06-30")]
    }
  },
  created() {
    this.statLogists(this.period)
    this.bxUsers()
  },
  methods: {
    ...mapActions(["statLogists", "bxUsers"]),
    changeDate(type) {
      const periods = {
        month: [moment().subtract('1', 'month').set({ h: 0, m: 0, s: 0 }), moment().subtract('1', 'day').set({ h: 23, m: 59, s: 59 })],
        week: [moment().subtract('1', 'week').set({ h: 0, m: 0, s: 0 }), moment().subtract('1', 'day').set({ h: 23, m: 59, s: 59 })],
        day: [moment().subtract('1', 'day').set({ h: 0, m: 0, s: 0 }), moment().subtract('1', 'day').set({ h: 23, m: 59, s: 59 })],
      }
      this.statLogists(periods[type])
    },
    getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          
          if(index === 0) {
            sums[index] = "Итого";
            console.log(data);  
            return; 
          }
          const values = data.map(item => item[column.property]); 
         
          if(index === 18) {
            sums[index] = parseInt(sums[11]/sums[5]);  
            return sums; 
          }
          if(index === 17) {
            sums[index] = parseInt(sums[10]/sums[4]);
            return sums;  
          }
          if(index === 19) {
            sums[index] = parseInt(sums[12]/sums[6]);
            return sums;  
          }
          if(index === 20) {
            sums[index] = parseInt(sums[10]/sums[1]);
            return sums;  
          }

         

          if(index === 13) {
            sums[index] = (sums[12]/sums[10]*100).toFixed(2) + "%"; 
            return sums; 
          }
          if(index === 9) {
            sums[index] = (sums[5]/sums[2]*100).toFixed(2) + "%"; 
            return sums; 
          }
          if(index === 7) {
            sums[index] = (sums[6]/sums[4]*100).toFixed(2) + "%";
            return sums; 
          }
          if(index === 8) {
            sums[index] = (sums[4]/sums[1]*100).toFixed(2) + "%"; 
            return sums; 
          }
           if (!values.every(value => typeof value === "string")) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          } else {
            sums[index] = (values.reduce((prev, curr) => {
              const value = Number(curr.replace("%", ""));
              return prev + value;
            }, 0)/data.length).toFixed(2) + "%"
          }
        })
        //sums[5] = (sums[3]/sums[1]*100).toFixed(2); 
        //sums[6] = (sums[4]/sums[1]*100).toFixed(2); 
        //sums[7] = data.map();
        //sums[8] = 100;

    
        return sums; 
    }
  },
  computed: {
    ...mapGetters(["logists", "bxusers"]),
    user() {
      return id => {
        const user = this.bxusers.find(e => e.ID == id);
        if(!user) return '';
        return user.NAME + " " + user.LAST_NAME;
      }
    }
  }
}
</script>
<style>
.page-statlogist {
  padding: 40px;
}
</style>
